import {
  FASTElement,
  attr,
  html,
  repeat,
  customElement,
  when,
  css,
  observable,
  ref,
} from "@microsoft/fast-element";
import { SearchResponse, performSearch } from "./api";
import { ChatElement } from "./chat";
import { state, storeState } from "./store";
import { T } from "./texts";

const template = html<SearchComponent>`
  <section @scroll=${(x, c) => x.onScroll()} class="chat" ${ref("chatElement")}>
    <chat-bubble>${x => T.t.opening}</chat-bubble>
    ${repeat(
      (y) => y.state.messages,
      html<SearchResponse>`
        <answers-component
          :dialog=${(x, c) => c.parent.chat.dialog}
          :response=${(x) => x}
        ></answers-component>
      `
    )}
    ${when(
      (x) => x.state.currentQuestion !== null,
      html<SearchComponent>`<chat-bubble isUser="true"
        >${(x) => x.state.currentQuestion}</chat-bubble
      >`
    )}
    ${when(
      (x) => x.state.loading,
      html<SearchComponent>` <spinner-element></spinner-element> `
    )}
  </section>

  <form ?disabled=${(x) => x.state.loading} @submit=${(x, c) => x.onSubmit(c.event)}>
    ${when(
      (x) => !x.isAtBottom,
      html<SearchComponent>`
        <button @click=${(x, c) => x.scrollDown()} id="scroll-to-bottom">
          <material-icon name="arrow_downward"></material-icon>
        </button>
      `
    )}
    <input
      ?disabled=${(x) => x.state.loading}
      @input="${(x, c) => x.onChange(c.event)}"
      ${ref("questionInput")}
      name="question"
      placeholder="${x => T.t.placeholder}"
      type="text"
    />
    <button ?disabled="${(x) => !x.inputText || x.state.loading}" type="submit">
      <material-icon name="send"></material-icon>
    </button>
  </form>
`;
const styles = css`
  input {
    flex: 1 1 auto;
    min-width: 0;
    background-color: rgb(var(--black) / 5%);
    border-radius: 0.5rem;
    border: none;
    padding: 0.3em 0.7em;

    box-sizing: border-box;
    outline: inherit;
  }
  button {
    font-size: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 0.3em 0.7em;
    color: rgb(var(--white));
    background: rgb(var(--red));
    border-radius: 9999px;
    cursor: pointer;
    border: none;
    aspect-ratio: 1 / 1;
  }

  button span {
    font-size: 0.8rem;
    font-weight: bold;
  }

  @media (max-width: 620px) {
    button span {
      display: none;
    }
  }

  button#scroll {
  }

  button[disabled] {
    background: grey;
  }

  input:focus-visible {
    box-shadow: 0 0 0 4px rgb(var(--focus) / 100%),
      inset 0 0 0 9999px rgb(var(--focus) / 10%);

    outline-offset: 0px;
  }

  form {
    display: flex;
    padding: 1.4em 0 0.8em 0;
    gap: 1em;
    position: relative;
    border-top: 1px solid rgb(var(--black) / 10%);
  padding: 1rem 2rem;
    background: white;
  }
  p {
    background: white;
  }
  section.chat {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    //    background: linear-gradient(180deg, #f0f8ff, #fff3f3);
    background: white;
    position: relative;

    height: min(620px, -220px + 100vh);
  }

  form button#scroll-to-bottom {
    position: absolute;
    top: -52px;
    right: 5px;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    background: white;
    color: black;

    box-shadow: 0 0 60px rgb(var(--black) / 50%);
  }
`;

@customElement({
  name: "search-bot-component",
  template,
  styles,
})
export class SearchComponent extends FASTElement {
  questionInput!: HTMLInputElement;
  chatElement!: HTMLElement;
  @observable inputText: string = "";

  @observable isAtBottom = true;

  @attr chat!: ChatElement;
  @attr url!: string;
  @observable state = state;
 

  connectedCallback(): void {
    super.connectedCallback();

    this.scrollToLatest("instant");
  }

  storeResults() {
    storeState();
  }

  scrollToLatest(behavior: ScrollBehavior = "smooth") {
    setTimeout(() => {
      this.chatElement.lastElementChild?.scrollIntoView({
        behavior: behavior,
      });
    }, 10);
  }

  async onChange(c: Event) {
    this.inputText = (c.target as HTMLInputElement).value;
  }

  async scrollDown() {
    this.chatElement.scrollTo({
      behavior: "smooth",
      top: this.chatElement.scrollHeight - this.chatElement.offsetHeight,
    });
  }

  async onScroll() {
    this.isAtBottom =
      this.chatElement.scrollTop >=
      this.chatElement.scrollHeight - this.chatElement.offsetHeight - 10;
  }

  async forwardConversation(email: string, message: string, fachstelle: string) {
    const payload = {
      conversation: this.state.messages,
      email: email,
      message: message,
      fachstelle: fachstelle,
    };

    fetch(this.chat.forwardUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  }

  async onSubmit(c: Event) {
    const form = new FormData(c.target as HTMLFormElement);
    const question = (form.get("question") || "") as string;
    this.state.currentQuestion = question;
    this.questionInput.value = "";
    this.state.loading = true;
    this.scrollToLatest();
    let answer = await performSearch(question, this.state, this.url)
    
    if (!this.state.threadId) {
      this.state.threadId = answer.threadId;
    }

    this.state.messages.push(answer);
    this.scrollToLatest();
    this.onScroll();

    this.state.currentQuestion = null;
    this.state.loading = false;
    this.storeResults();
  }
}
