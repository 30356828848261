import {
  FASTElement,
  customElement,
  html,
  observable,
} from "@microsoft/fast-element";

const template = html<SpinnerComponent>`<chat-bubble
  >${(x) => x.text}</chat-bubble
>`;

@customElement({
  name: "spinner-element",
  template,
})
export class SpinnerComponent extends FASTElement {
  @observable text = "...";

  constructor() {
    super();
    setInterval(() => {
      this.text += ".";
      if (this.text.length > 3) {
        this.text = "";
      }
    }, 500);
  }
}
