import {
  attr,
  css,
  customElement,
  FAST,
  FASTElement,
  html,
  observable,
  ref,
  repeat,
  when,
} from "@microsoft/fast-element";
import { ChatElement } from "./chat";
import { T } from "./texts";
import { Center, getCenters } from "./api";


const template = html<ForwardDialog>`<dialog ${ref("dialog")}>
  <button id="close" @click="${(x) => x.dialog.close()}">
    <material-icon name="close"></material-icon>
  </button>
  <div class="wrapper">
    <h3>${x => T.t.title}</h3>
    <p>
      ${x => T.t.text}
    </p>
    <form ${ref("form")} @submit=${(x, c) => x.onSubmit(c.event)}
      >
      <div>
        <label>${x => T.t.email}</label>
        <input @input=${(x) => x.onChange()} @blur=${x => x.onChange()} name="email" type="text" />
        ${when(
        (x) => x.email && !x.isEmailValid(),
        html`
        <small class="error">${x => T.t.emailError}</small>
        `
      )}
        <span>
      </div>

      <div>
        <label>${x => T.t.fachstelle}</label>
        <fast-combobox autocomplete="both" name="fachstelle">
          ${repeat(x => x.centers, html<Center>`<fast-option value="${x => x.email}">${x => x.label}
            </fast-option>`)}
        </fast-combobox>
      </div>

      <div>
        <label>${x => T.t.message}</label>
        <textarea @input="${(x) => x.onChange()}" @keyup="${x => x.onChange()}" name="message"></textarea>
        <small></small>
      </div>
      <button ?disabled="${(x) => !x.isReady()}" id="submit" type="submit">
        <material-icon name="send"><span>${x => T.t.send}</span></material-icon>
      </button>

    </form>
  </div>
</dialog>`;

const styles = css`
  dialog {
    border: none;
    border-radius: 0.5rem;
    width: 50%;

    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    padding: 0;
    max-width: 720px;
    position: relative;
  }

  .error {
    color: rgb(var(--red));
  }
  
  :host {
    --neutral-fill-input-rest: white;
  }

  .wrapper {
    display: grid;
    grid-template-areas:
      ". close"
      "title title"
      "intro intro"
      "form form";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto auto;
    padding: 3rem 3.5rem;
  }
  form {
    grid-area: form;
    display: grid;
    grid-template-columns: auto;
  }

  h3 {
    color: rgb(var(--red));
    margin-top: 0;
    margin-bottom: 0;
    grid-area: title;
  }

  p {
    grid-area: intro;
  }

  label {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.5em;
  }

  form > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    gap: 8px;
  }

  textarea {
    height: 12em;
    resize: none;
  }

  input,
  textarea {
    border-radius: 0.5rem;
    padding: 0.3em 0.7em;

    box-sizing: border-box;
    outline: inherit;
    border: 1px solid rgb(var(--black) / 10%);
    font-size: 1rem;
  }

  dialog::backdrop {
    background: rgb(var(--black) / 50%);
  }

  input:focus-visible,
  textarea:focus-visible {
    box-shadow: 0 0 0 4px rgb(var(--focus) / 100%),
      inset 0 0 0 9999px rgb(var(--focus) / 10%);

    outline-offset: 0px;
  }
  #submit {
    cursor: pointer;
    justify-self: start;
  }

  #close {
    grid-area: close;
    background: white;
    border: none;
    cursor: pointer;
    align-self: start;
    position: absolute;
    top: 1em;
    right: 1em;
  }

  button#submit {
    font-size: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 0.3em 0.7em;
    color: rgb(var(--red));
    background: white;
    border-radius: 9999px;
    cursor: pointer;
    border: solid 1px rgb(var(--red));
  }

  button#submit:disabled {
    cursor: not-allowed;
    border: solid 1px rgb(var(--red) / 20%);
    color: rgb(var(--red) / 20%);
  }

  button span {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

@customElement({ name: "forward-dialog", template, styles })
export class ForwardDialog extends FASTElement {
  @attr chat!: ChatElement;
  dialog!: HTMLDialogElement;
  form!: HTMLFormElement;
  fachstelle: string = "";

  centers!: Center[];

  @observable email: string = "";
  @observable message: string = "";

  connectedCallback(): void {
    getCenters().then(centers => { this.centers = centers; super.connectedCallback() })
  }

  openDialog() {
    this.dialog.showModal();
  }

  isEmailValid() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(this.email);
  }

  isReady() {
    return this.isEmailValid();
  }

  onChange() {
    const data = new FormData(this.form);
    this.email = data.get("email") as string;
    this.message = data.get("message") as string;
    this.fachstelle = data.get("fachstelle") as string;
  }

  async onSubmit(e: Event) {
    await this.chat.search.forwardConversation(this.email, this.message, this.fachstelle);
    this.dialog.close();
  }
}
