
interface Texts {
    lang: string,
    greeting: string,
    opening: string,
    more: string,
    contact: string,
    submit: string,
    title: string,
    text: string,
    info: string,
    email: string,
    message: string,
    messageInfo: string,
    send: string,
    emailError: string,
    fachstelle: string,
    name: string,
    placeholder: string,
}

const DE: Texts = {
    lang: 'de',
    name: 'Ihr digitaler Berater',
    greeting: "Grüezi",
    opening: "Wie lautet Ihre Frage?",
    more: 'Haben Sie weitere Fragen oder möchten Sie an eine regionale Fachstelle weitergeleitet werden?',
    contact: 'Fachstelle kontaktieren',
    submit: "Senden",
    title: 'Wenden Sie sich an eine regionale Fachstelle',
    text: 'Wir beraten Sie kompetent und schnell. Mit dem Absenden erklären Sie sich damit einverstanden, dass die Fachstelle Ihre Angaben zum Zweck der Beantwortung ihrer Frage verarbeiten darf.',
    info: " Unser Chatbot hilft dir beim Durchsuchen unserer Website. Es handelt sich bei den Antworten nicht um geprüfte Ratschläge. Erfahre hier mehr über dieses Tool.",
    email: "E-Mail",
    emailError: "Bitte eine gültige E-Mail Adresse eingeben!",
    message: "Nachricht",
    messageInfo: "Beschreiben Sie Ihr Anliegen möglichst präzis, damit wir Ihnen schnell helfen können",
    send: "Anfrage abschicken",
    fachstelle: "Fachstelle",
    placeholder: "Schreiben Sie Ihre Frage",
}

const FR: Texts = {
    lang: 'fr',
    name: "???",
    greeting: 'Bonjour!',
    opening: 'Quelle est votre question ?',
    more: "Vous avez d'autres questions ou vous souhaitez être dirigé vers un centre spécialisé régional ?",
    contact: "Contacter le centre spécialisé",
    submit: "???",
    title: "Adressez-vous à un centre régional spécialisé",
    text: "Nous vous conseillons de manière compétente et rapide. En envoyant ce formulaire, vous acceptez que le Centre traite vos données dans le but de répondre à votre question.",
    info: "!!!",
    email: "Courriel",
    emailError: "!!!",
    message: "!!!",
    messageInfo: "???",
    send: "???",
    fachstelle: "????",
    placeholder: "????",
}


// const IT: Texts = {

// }

// const EN: Texts = {

// }

export let T: { t: Texts } = { t: DE };

export function getTexts(lang: string): Texts {
    if (lang == 'fr')
        return FR

    return DE

}