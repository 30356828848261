import { comboboxStyles, fastCombobox, fastOption, provideFASTDesignSystem } from "@microsoft/fast-components";
import { SearchComponent } from "./search";
import { AnswersComponent } from "./answer";
import { ResultComponent } from "./result";
import { SpinnerComponent } from "./spinner";
import { ChatElement } from "./chat";
import { ChatBubble } from "./chatbubble";
import { MoreElement } from "./more";
import { BubbleComponent } from "./bubble";
import { MaterialIcon } from "./material-icon";
import { ForwardDialog } from "./forward";
import { css } from "@microsoft/fast-element";

provideFASTDesignSystem().register(
  SearchComponent,
  AnswersComponent,
  ResultComponent,
  SpinnerComponent,
  BubbleComponent,
  ChatElement,
  ChatBubble,
  MoreElement,
  ForwardDialog,
  MaterialIcon,
  fastCombobox({
    styles: (ctx, def) => css`
    ${comboboxStyles(ctx, def)}
    :host {
      --neutral-fill-input-rest: white;
      --neutral-fill-input-hover: white;
      --neutral-stroke-active: white;
      --neutral-fill-stealth-rest: white;
      --neutral-foreground-rest: black;
    }
    .selected-value {
      color: black;
    }
    `
  }),
  fastOption(),
);
