import {
  FASTElement,
  html,
  css,
  customElement,
  attr,
  ref,
  observable,
} from "@microsoft/fast-element";
import { BubbleComponent } from "./bubble";
import { ForwardDialog } from "./forward";
import { SearchComponent } from "./search";
import { state } from "./store";
import { T } from "./texts";

const template = html<ChatElement>`
  <section class="chat ${(x) => (x.visible ? " visible" : "hidden" )}" style="z-index: ${x => x.state.zIndex};">
    <h2>${x => T.t.name}</h2>
    <button @click=" ${(x) => (x.bubble.isOpen = false)}">
      <material-icon name="close"></material-icon>
    </button>
    <p>
      ${x => T.t.info}
    </p>
    <search-bot-component ${ref("search")} :url=${x => x.url} :chat=${(x) => x}
      ></search-bot-component>
  </section>
  <forward-dialog :chat=${(x)=> x} ${ref("dialog")}></forward-dialog>
`;
const styles = css`
  .chat {
    max-width: 100%;
    width: 600px;
    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    border-radius: 0.5rem;
    background: rgb(var(--red));
    position: fixed;
    right: 30px;
    top: calc(100vh);
    z-index: -1;
    display: grid;
    grid-template-areas:
      "title close"
      "chat chat"
      "intro intro";
    grid-template-rows: auto auto minmax(10px, 1fr);
    grid-template-columns: auto minmax(10px, 1fr);
    transition: all 200ms ease-out;
    opacity: 0;
  }

  @media (max-width: 620px) {
    .chat {
      right: 3px;
    }
  }

  .chat.visible {
    opacity: 1;
    top: max(10px, calc(100vh - 850px));
  }


  h2 {
    color: white;
    grid-area: title;
    align-self: center;
    font-size: 1.2rem;
    border-bottom: 1px solid rgb(var(--black) / 10%);
    font-weight: normal;
    padding: 0.3em 2em;
  }

  button {
    grid-area: close;
    font-size: 1rem;
    justify-self: end;
    align-self: start;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0.5rem;
    color: white;
  align-self: center;
  }

  p {
    grid-area: intro;
    font-size: small;
    background: white;
    color: grey;
    text-align: center;
    padding: 1em 1em;
    margin: 0;
  border-radius: 0px 0px 7px 7px;
  }

  search-bot-component {
    grid-area: chat;
  }

  :root {
    --black: 47 47 47;
  }
`;

@customElement({
  name: "chat-element",
  template,
  styles,
})
export class ChatElement extends FASTElement {
  @attr forwardUrl = "https://hook.eu1.make.com/fvw8536stl559x5jb71ogvtpxjrkcs5h";
  @attr({ mode: "boolean" }) visible: boolean = false;

  @attr bubble!: BubbleComponent;
  @attr toggle!: any;
  @attr url!: string;
  @observable state = state;

  dialog!: ForwardDialog;
  search!: SearchComponent;
}
