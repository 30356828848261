import {
  FASTElement,
  attr,
  css,
  customElement,
  html,
} from "@microsoft/fast-element";
import { Result } from "./api";

const template = html<ResultComponent>`
  <h3>
    <a target="_blank" :href="${(x) => x.result.url}">
      ${(x) => x.result.title}
    </a>
  </h3>
  <p>${(x) => x.result.snippet}</p>
`;

const styles = css`
  h3 a {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;
    margin: 0;
    padding: 0;
    color: rgb(var(--red));
    text-decoration: underline;
  }

  h3 {
    margin: 0;
    margin-top: 0.3em;
    margin-bottom: 5px;
  }

  p {
    padding-left: 0px;
    margin-top: 0;
    padding-bottom: 0.3em;
    margin-bottom: 0;
  }
`;

@customElement({
  name: "result-component",
  template,
  styles,
})
export class ResultComponent extends FASTElement {
  @attr result!: Result;
}
