
import { observable } from "@microsoft/fast-element";
import { SearchResponse, Document, Result, performSearch } from "./api";


export class AppState {
    @observable name: string = "Hallo";
    @observable messages: SearchResponse[] = [];
    @observable currentQuestion: string | null = null;
    @observable loading = false;
    @observable threadId: string | null = null;
    zIndex: number = 8000;
}

function loadState() {
    let state = new AppState()
    const stored = sessionStorage.getItem('state');
    if (stored) {
        let data = JSON.parse(stored);
        Object.assign(state, data);
        state.loading = false;
    }
    return state;
}



export const state = loadState();

export function storeState() {
    sessionStorage.setItem('state', JSON.stringify(state))
}