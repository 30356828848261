import {
  FASTElement,
  attr,
  css,
  customElement,
  html,
  repeat,
} from "@microsoft/fast-element";
import { Result, SearchResponse } from "./api";
import { ForwardDialog } from "./forward";

const template = html<AnswersComponent>`
  <chat-bubble isUser="true">${(x) => x.response.question}</chat-bubble>
  <chat-bubble>
    <h2>${(x) => x.response.tools.answer}</h2>
    ${repeat(
      (x) => x.response.tools.results,
      html<Result>` <result-component :result=${(x) => x}></result-component> `
    )}
  </chat-bubble>
  <more-element :dialog=${(x) => x.dialog}></more-element>
`;
const styles = css`
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1rem;
  }
`;

@customElement({
  name: "answers-component",
  template,
  styles,
})
export class AnswersComponent extends FASTElement {
  @attr response!: SearchResponse;
  @attr dialog!: ForwardDialog;

  connectedCallback(): void {
    super.connectedCallback();
  }
}
