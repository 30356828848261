import { AppState } from "./store";
import { T } from "./texts";


export type Document = {
  id: number;
  content: string;
  title: string;
  page: number;
};

export type Result = {
  title: string;
  id: number;
  url: string;
  snippet: string;
};


export type SearchResponse = {
  tools: {
    answer: string;
    results: Result[];
  };
  content: string;
  docs: Document[];
  question: string;
  threadId: string;
};

export async function performSearch(query: string, state: AppState, url: string) {
  let formData = new FormData();
  formData.append("question", query);
  formData.append("tool_choice", "required");

  let response = state.threadId ? await queryThread(formData, state, url) : await newThread(formData, state, url);

  let data: SearchResponse = await response.json();
  data.question = query;

  return data;
}

async function queryThread(query: FormData, state: AppState, url: string) {
  query.append("threadId", state.threadId!);

  let response = await fetch(url, {
    method: "POST",
    body: query,
  });

  return response

}

async function newThread(query: FormData, state: AppState, url: string) {

  let response = await fetch(url + "init/", {
    method: "POST",
    body: query,
  });

  return response;

}

interface EmailEntry {
  type: string,
  value: string,
}

function getEmail(d: EmailEntry[]) {
  for (let x of d) {
    if (x.type == 'main') {
      return x.value
    }
  }
  return '';
}

function getName(d: any) {
  let lang = T.t.lang;

  let name = d.name[lang];

  if (name == '') {
    return '';
  }

  return `${name} - ${d.city}`
}

export interface Center {
  label: string,
  email: string,
}

export async function getCenters(): Promise<Center[]> {
  let response = await fetch("https://static.d-o.li/ahs-chatbot/centers.json", {

  }).then(response => response.json());


  return response['data'].map(
    (e: any) => { return { 'label': getName(e), 'email': getEmail(e.emailAddresses) } }
  ).filter(
    (x: any) => x.email != '' && x.label != ''
  );

}