import {
  attr,
  css,
  customElement,
  FASTElement,
  html,
} from "@microsoft/fast-element";

@customElement({
  name: "material-icon",
  template: html`<span class="material-symbols-outlined">${(x) => x.name}</span>
    <slot></slot>`,
  styles: css`
    :host {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    .material-symbols-outlined {
      font-family: "Material Symbols Outlined";
      font-weight: normal;
      font-style: normal;
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      font-feature-settings: "liga";
    }
  `,
})
export class MaterialIcon extends FASTElement {
  @attr name!: string;
}
